<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false" />
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";

export default {
  name: "SignatureFranchiseView",
  components: {
    ViewTemplateConfiguration,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/signature-file/total-document-module",
        showChecks: false,
        headerTable: [
          {
            field: "total",
            title: "Total Emitido",
            type: "text",
          },
          {
            field: "fullPeriod",
            title: "Período",
            type: "text",
          },

        ],
      },
    };
  },

};
</script>

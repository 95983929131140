<template>
    <div>
        <Loading type="line" :center="false" v-if="loading" />
        <table class="table table-responsive-xs">
            <thead>
                <tr>
                    <th>
                        <span class="title-header">Descrição</span>
                    </th>
                    <th class="text-center">
                        <span class="title-header">
                            <span>Vencimento</span>
                        </span>
                    </th>
                    <th class="text-center">
                        <span class="title-header">
                            <span>Status</span>
                        </span>
                    </th>
                    <th class="text-center">
                        <span class="title-header">Valor</span>
                    </th>
                    <th class="text-center">
                        <span class="title-header">2º via Boleto</span>
                    </th>
                </tr>
            </thead>
            <tbody v-for="billet in data">
                <tr>
                    <td>{{ billet.description }}</td>
                    <td class="text-center">{{ billet.dueDate | moment("DD/MM/YYYY") }}</td>
                    <td class="text-center">
                        <span class="badge badge-primary" v-if="billet.status == 'Agendada'">A vencer</span>
                        <span class="badge badge-success" v-if="billet.status == 'RECEIVED'">Pago</span>
                        <span class="badge badge-warning" v-if="billet.status == 'PENDING'">Pendente</span>
                        <span class="badge badge-danger" v-if="billet.status == 'OVERDUE'">Em atraso</span>
                    </td>
                    <td class="text-center">{{ billet.value | currency }}</td>
                    <td class="text-center">
                        <span class="link" @click="openLink(billet)">Visualizar</span>
                    </td>
                </tr>
            </tbody>
            <tbody v-show="data.length == 0">
                <tr>
                    <td colspan="12">
                        <span>Nenhum registro encontrado!</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions } from "vuex";

export default {
    name: "Payment",
    components: {
        Loading
    },
    data() {
        return {
            urlGetApi: "/api/v1/adm/asaas/get-all",
            data: [],
            loading: true
        }
    },
    mounted() {
        this.getAll();
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        getAll() {
            this.loading = true;
            let params = { url: this.urlGetApi, obj: { noPaginated: true } };
            this.getApi(params).then((response) => {
                this.data = response.content;
                this.loading = false;
            });

        },
        openLink(billet) {
            const link = billet.invoiceUrl;
            window.open(link, '_blank');
        }

    },
}
</script>

<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.link {
    color: #3f529b;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}
</style>
<template>
    <div>
        <Panel :module="panel.module" :title="panel.title" :showFilter="panel.showFilter" :showSearch="panel.showSearch"
            :showButtons="panel.showButtons">
            <div slot="content-main">
                <div>
                    <br />
                    <b-tabs>
                        <b-tab title="Pagamentos">
                            <Payment />
                        </b-tab>
                        <b-tab title="Notas Fiscais">
                            <Nfse />
                        </b-tab>
                        <b-tab v-if="hasModule('Signature')" title="Assina..Digital">
                            <SignatureFranchise />
                        </b-tab>
                        <b-tab v-if="hasModule('Billet')" title="Boleto">
                            <BilletFranchise />
                        </b-tab>
                        <b-tab v-if="hasModule('TaxDocument')" title="Nota Fiscal">
                            <b-tabs vertical>
                                <b-tab title="Nfse">
                                    <NfseFranchise />
                                </b-tab>
                                <b-tab title="Nfe">
                                    <NfeFranchise />
                                </b-tab>
                            </b-tabs>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </Panel>
    </div>
</template>

<script>


import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Payment from '../../../components/modules/adm/plan/Payment.vue'
import BilletFranchise from './BilletFranchise.vue'
import SignatureFranchise from './SignatureFranchise.vue'
import NfseFranchise from './NfseFranchise.vue'
import NfeFranchise from './NfeFranchise.vue'
import Nfse from '../../../components/modules/adm/plan/Nfse.vue'

import { mapGetters, mapState, mapMutations } from "vuex";


export default {
    name: "PlanView",
    components: { Panel, SignatureFranchise, BilletFranchise, NfseFranchise, NfeFranchise, Payment, Nfse },
    data() {
        return {
            panel: {
                module: "Adm",
                title: "Plano",
                showFilter: false,
                showSearch: false,
                showButtons: false,
            },
        };
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        ...mapGetters("user", ["hasModule"]),
        ...mapState("user", ["userLogged"]),
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),
    }
};
</script>